import { seasons } from "core/api/seasons"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useSeasons() {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["seasons"], () =>
    getToken().then((token) => {
      return seasons.getSeasons(token)
    })
  )

  return {
    ...queryResponse,
    seasons: queryResponse?.data?.items ?? [],
    totalCount: queryResponse?.data?.totalCount ?? 0,
    totalPages: queryResponse?.data?.totalPages ?? 0,
  }
}
