import Navbar from "./components/Navbar/Navbar"
import React from "react"

export default function AppLayout({ children }) {
  return (
    <>
      <Navbar />
      <div className='body'>
        <div sx={{ marginTop: 1 }}>{children}</div>
      </div>
    </>
  )
}
