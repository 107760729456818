const allScopes = [
  "update:circuit",
  "get:seasons",
  "get:constructors",
  "get:constructor",
  "get:constructorhistories",
  "get:constructorhistory",
  "update:constructor",
  "update:constructorhistory",
  "update:driver",
  "update:driverhistory",
  "get:drivers",
  "get:driverhistories",
  "get:driverhistory",
  "get:driver",
]

export const scopes = allScopes.join(" ")
