import GrandsPrix from "components/GrandsPrix"
import ListPageContainer from "components/Shared/ListPageContainer"
import LoadingProgress from "components/Shared/LoadingProgress"
import { useParams } from "react-router-dom"
import useSeason from "core/hooks/useSeason"
import useSeasonGrandsPrix from "core/hooks/useSeasonGrandsPrix"

export default function SeasonGrandsPrixPage() {
  const { seasonId } = useParams()

  const { grandsPrix, isLoading: isLoadingGrandsPrix } =
    useSeasonGrandsPrix(seasonId)
  const { season, isLoading: isLoadingSeason } = useSeason(seasonId)

  if (isLoadingGrandsPrix || isLoadingSeason) {
    return <LoadingProgress />
  }

  return (
    <ListPageContainer
      title={`List of grands prix for the season ${season.year} : ${grandsPrix.length}`}
    >
      <GrandsPrix grandsPrix={grandsPrix} />
    </ListPageContainer>
  )
}
