import ListPageContainer from "components/Shared/ListPageContainer"
import LoadingProgress from "components/Shared/LoadingProgress"
import TeamHistoriesList from "components/TeamHistoriesList"
import { useParams } from "react-router-dom"
import useSeason from "core/hooks/useSeason"
import useSeasonTeams from "core/hooks/useSeasonTeams"

export default function SeasonTeamsPage() {
  const { seasonId } = useParams()

  const { teams, isLoading: isLoadingTeams } = useSeasonTeams(seasonId)
  const { season, isLoading: isLoadingSeason } = useSeason(seasonId)

  if (isLoadingTeams || isLoadingSeason) {
    return <LoadingProgress />
  }

  return (
    <ListPageContainer
      title={`List of teams for the season ${season.year} : ${teams.length}`}
      // navs={[
      //   { link: `/teams`, title: "Teams" },
      //   {
      //     link: `/teams/${teamId}/edit`,
      //     title: teamId,
      //   },
      // ]}
    >
      <TeamHistoriesList histories={teams} />
    </ListPageContainer>
  )
}
