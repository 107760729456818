import { MenuItem, Select, TableCell, TableRow, TextField } from "@mui/material"

import DisplayGroup from "components/Shared/DisplayGroup"

export default function RaceParticipationEdit({
  participation,
  updateParticipation,
  updateTeam,
  updateTeamConstructor,
  setSuccessDisplay,
  setErrorDisplay,
  teams,
}) {
  const displayResultTime = () => {
    if (participation.position === 1) {
      return participation.result.time
    }

    if (
      participation.result.time == null &&
      participation.result.lapsBehindLeader == null
    ) {
      return
    }

    if (participation.result.time == null) {
      return `+${participation.result.lapsBehindLeader} laps`
    }

    return `+${participation.result.time}s`
  }

  const currentTeam = () => {
    return teams.find((team) => {
      return team.teamId === participation.team.id
    })
  }

  return (
    <TableRow
      key={participation.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component='td' scope='row' size='small'>
        <TextField
          id={`position-${participation.id}`}
          label='Pos'
          defaultValue={participation.result.position}
          size='small'
          onChange={(event) => {
            setSuccessDisplay(false)
            setErrorDisplay(false)
            updateParticipation(
              participation.id,
              "result.position",
              event.target.value
            )
          }}
        />
      </TableCell>
      <TableCell component='td' scope='row' size='small'>
        <TextField
          id={`finishedPosition-${participation.id}`}
          size='small'
          label='FS Pos'
          defaultValue={participation.result.finishedPosition}
          onChange={(event) => {
            setSuccessDisplay(false)
            setErrorDisplay(false)
            updateParticipation(
              participation.id,
              "result.finishedPosition",
              event.target.value
            )
          }}
        />
      </TableCell>
      <TableCell component='td' scope='row'>
        <DisplayGroup
          groups={[
            { title: "grid", value: participation.result.grid },
            {
              title: "laps",
              value: participation.result.laps,
            },
            {
              title: "points",
              value: participation.result.points,
            },
            {
              title: "time",
              value: displayResultTime(),
            },
          ]}
        />
      </TableCell>
      <TableCell component='td' scope='row'>
        <Select
          id={`result-status-${participation.id}`}
          value={participation.result.resultStatus.status}
          onChange={(event) => {
            setSuccessDisplay(false)
            setErrorDisplay(false)
            updateParticipation(
              participation.id,
              "result.resultStatus.status",
              event.target.value
            )
          }}
        >
          <MenuItem value={"Ok"}>Ok</MenuItem>
          <MenuItem value={"Dnf"}>Dnf</MenuItem>
          <MenuItem value={"Dns"}>Dns</MenuItem>
          <MenuItem value={"Dsq"}>Dsq</MenuItem>
        </Select>
      </TableCell>
      <TableCell component='td' scope='row'>
        {`${participation.driver.firstName} ${participation.driver.lastName}`}
      </TableCell>
      <TableCell component='td' scope='row'>
        {participation.constructor.name}
      </TableCell>
      <TableCell component='td' scope='row'>
        <Select
          id={`result-team-${participation.team.id}`}
          value={participation.team.id}
          fullWidth={true}
          onChange={(event) => {
            setSuccessDisplay(false)
            setErrorDisplay(false)
            updateTeam(participation.id, event.target.value)
          }}
        >
          {teams.map((team) => (
            <MenuItem value={team.teamId}>{team.shortName}</MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell component='td' scope='row'>
        <Select
          id={`result-team-cm-${participation.team.constructorManufacturer.id}`}
          value={`${participation.team.constructorManufacturer.id}+${participation.team.engineManufacturer.id}`}
          fullWidth={true}
          onChange={(event) => {
            setSuccessDisplay(false)
            setErrorDisplay(false)
            updateTeamConstructor(
              participation.id,
              participation.team.id,
              event.target.value
            )
          }}
        >
          {currentTeam().constructors.map((constructor) => (
            <MenuItem
              key={`${constructor.constructor.id}+${constructor.engineManufacturer.id}`}
              value={`${constructor.constructor.id}+${constructor.engineManufacturer.id}`}
            >
              {constructor.constructor.name} -{" "}
              {constructor.engineManufacturer.name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
    </TableRow>
  )
}
