import ListPageContainer from "components/Shared/ListPageContainer"
import LoadingProgress from "components/Shared/LoadingProgress"
import SeasonsList from "components/SeasonsList"
import { useLocation } from "react-router-dom"
import useSeasons from "core/hooks/useSeasons"

export default function SeasonsPage() {
  const location = useLocation()
  const pageNumber = new URLSearchParams(location.search).get("pageNumber") ?? 1
  const pageSize = new URLSearchParams(location.search).get("pageSize") ?? 100

  const {
    seasons,
    isLoading: isLoadingTeams,
    totalPages,
    totalCount,
  } = useSeasons(pageNumber, pageSize)

  if (isLoadingTeams) {
    return <LoadingProgress />
  }

  return (
    <ListPageContainer
      title={`Seasons: ${totalCount}`}
      totalPages={totalPages}
      currentPage={parseInt(pageNumber)}
      pageSize={pageSize}
      paginationLink={"seasons"}
    >
      <SeasonsList seasons={seasons} />
    </ListPageContainer>
  )
}
