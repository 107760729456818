import { grandsPrix } from "core/api/grandsPrix"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useGrandsPrix(pageNumber, pageSize) {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["grands-prix", { page: pageNumber }], () =>
    getToken().then((token) => {
      return grandsPrix.getGrandsPrix(pageNumber, pageSize, token)
    })
  )

  return {
    ...queryResponse,
    grandsPrix: queryResponse?.data?.items ?? [],
    totalPages: queryResponse?.data?.totalPages ?? 0,
    totalCount: queryResponse?.data?.totalCount ?? 0,
  }
}
