import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"

import moment from "moment"
import { useNavigate } from "react-router-dom"

export default function GrandPrixSchedules({ gpId, schedules }) {
  const navigate = useNavigate()
  if (schedules == null) {
    return
  }
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Order</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules.map((schedule) => (
            <TableRow
              key={schedule.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {schedule.type}
              </TableCell>
              <TableCell component='th' scope='row'>
                {schedule.order}
              </TableCell>
              <TableCell component='th' scope='row'>
                {`${moment(schedule.startDate).format("LLL")} - 
                ${moment(schedule.endDate).format("LT")}`}
              </TableCell>
              <TableCell component='th' scope='row'>
                {schedule.type === "MainRace" && (
                  <Button
                    onClick={() =>
                      navigate(`/grands-prix/${gpId}/races/${schedule.id}/edit`)
                    }
                  >
                    Edit
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
