import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import FormContainer from "components/Shared/FormContainer"
import InputGroup from "components/Shared/InputGroup"
import Navigation from "components/Navigation/Index"
import React from "react"
import SelectGroup from "components/Shared/SelectGroup"
import useAllConstructorManufacturers from "core/hooks/useAllConstructorManufacturers"
import useAllConstructors from "core/hooks/useAllConstructors"
import useAllEngineManufacturers from "core/hooks/useAllEngineManufacturers"
import useCountries from "core/hooks/useCountries"
import useTeamHistory from "core/hooks/useTeamHistory"

export default function TeamHistoryEditPage() {
  const params = useParams()

  const teamId = params.teamId
  const historyId = params.historyId

  const { countries, isLoading: isLoadingCountries } = useCountries()
  const {
    constructorManufacturers,
    isLoading: isLoadingConstructorManufacturers,
  } = useAllConstructorManufacturers()
  const { engineManufacturers, isLoading: isLoadingEngineManufacturers } =
    useAllEngineManufacturers()

  const {
    constructors: constructorLegacies,
    isLoading: isLoadingConstructors,
  } = useAllConstructors()

  const { history, updateTeamHistory, deleteTeamHistory, isLoading } =
    useTeamHistory(teamId, historyId)

  const navigate = useNavigate()

  const [fullName, setFullName] = React.useState("")
  const [shortName, setShortName] = React.useState("")
  const [color, setColor] = React.useState("")
  const [countryId, setCountryId] = React.useState("")
  const [constructors, setConstructors] = React.useState("")
  const [isError, setIsError] = React.useState(false)

  React.useEffect(() => {
    if (history != null) {
      setFullName(history.fullName)
      setCountryId(history.country.id)
      setShortName(history.shortName)
      setColor(history.color)
      setConstructors(history.constructors?.map(castToConstructor))
    }
  }, [history])

  const castToConstructor = (constructor) => {
    return {
      constructorId: constructor.constructor.id,
      engineManufacturerId: constructor.engineManufacturer.id,
      constructorLegacyId: constructor.constructorLegacy.id,
    }
  }

  const addConstructor = () => {
    setConstructors([
      ...constructors,
      {
        constructorId: "",
        engineManufacturerId: "",
        constructorLegacyId: "",
      },
    ])
  }

  const removeConstructor = (index) => {
    const newConstructors = constructors.filter((_, i) => i !== index)
    setConstructors(newConstructors)
  }

  const setConstructorsValue = (value, index, property) => {
    const newConstructors = constructors.map((constructor, i) => {
      if (i === index) {
        return {
          ...constructor,
          [property]: value,
        }
      }

      return constructor
    })

    setConstructors(newConstructors)
  }

  const handleDelete = async (event) => {
    event.preventDefault()
    const result = await deleteTeamHistory()

    if (result.status === 204) {
      navigate(`/teams/${teamId}/histories`)
      return
    }

    setIsError(true)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const result = await updateTeamHistory({
      fullName,
      shortName,
      color,
      countryId,
      constructors,
    })

    if (result.status === 204) {
      navigate(`/teams/${params.teamId}/histories`)
      return
    }

    setIsError(true)
  }

  if (
    isLoading ||
    isLoadingConstructorManufacturers ||
    isLoadingCountries ||
    isLoadingEngineManufacturers ||
    isLoadingConstructors
  ) {
    return <CircularProgress />
  }

  return (
    <Grid container gap={2}>
      <Grid item xs={12}>
        <Typography variant='h4' component='h1'>
          Edit team history {history.fullName}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Navigation
          navs={[
            { link: `/teams`, title: "teams" },
            {
              link: `/teams/${teamId}/edit`,
              title: teamId,
            },
            {
              link: `/teams/${teamId}/histories`,
              title: "histories",
            },
          ]}
        />
      </Grid>

      {isError && (
        <Grid item xs={12}>
          <Alert severity='error'>Error during the team history edit</Alert>
        </Grid>
      )}

      <FormContainer
        handleSubmit={handleDelete}
        buttonText='Delete team history'
        buttonColor='error'
        justifyContent='flex-end'
      />

      <FormContainer handleSubmit={handleSubmit}>
        <Grid item xs={12}>
          <InputGroup
            id='team-history-id'
            label='Id'
            value={history.id}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <InputGroup
            id='team-season'
            label='Season'
            value={history.season.year}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <InputGroup
            id='team-full-name'
            label='Full Name'
            value={fullName}
            onChange={(value) => setFullName(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputGroup
            id='team-short-name'
            label='Short Name'
            value={shortName}
            onChange={(value) => setShortName(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputGroup
            id='team-color'
            label='Color'
            value={color}
            onChange={(value) => setColor(value)}
          />
        </Grid>

        <Grid item xs={12}>
          <SelectGroup
            id='team-country'
            label='Country'
            value={countryId}
            onChange={(value) => setCountryId(value)}
          >
            {countries.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </SelectGroup>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={addConstructor}
          >
            Add constructor
          </Button>
        </Grid>

        {constructors &&
          constructors.map((constructor, index) => (
            <React.Fragment key={index}>
              {constructors.length > 1 && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant={"outlined"}
                    color={"error"}
                    onClick={() => removeConstructor(index)}
                  >
                    Remove constructor
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                <SelectGroup
                  id='team-constructors-manufacturers'
                  label='Constructor'
                  value={constructor.constructorId}
                  onChange={(value) =>
                    setConstructorsValue(value, index, "constructorId")
                  }
                >
                  {constructorManufacturers.map((constructor) => (
                    <MenuItem key={constructor.id} value={constructor.id}>
                      {constructor.name}
                    </MenuItem>
                  ))}
                </SelectGroup>
              </Grid>

              <Grid item xs={12}>
                <SelectGroup
                  id='team-engine-manufacturer'
                  label='Engine manufacturer'
                  value={constructor.engineManufacturerId}
                  onChange={(value) =>
                    setConstructorsValue(value, index, "engineManufacturerId")
                  }
                >
                  {engineManufacturers.map((engineManufacturer) => (
                    <MenuItem
                      key={engineManufacturer.id}
                      value={engineManufacturer.id}
                    >
                      {engineManufacturer.name}
                    </MenuItem>
                  ))}
                </SelectGroup>
              </Grid>

              <Grid item xs={12}>
                <SelectGroup
                  id='team-constructors'
                  label='Constructor legacy'
                  value={constructor.constructorLegacyId}
                  onChange={(value) =>
                    setConstructorsValue(value, index, "constructorLegacyId")
                  }
                >
                  {constructorLegacies.map((constructor) => (
                    <MenuItem key={constructor.id} value={constructor.id}>
                      {constructor.name}
                    </MenuItem>
                  ))}
                </SelectGroup>
              </Grid>
            </React.Fragment>
          ))}
      </FormContainer>
    </Grid>
  )
}
