import {
  Alert,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"

import { CheckCircleOutline } from "@mui/icons-material"
import LoadingProgress from "components/Shared/LoadingProgress"
import Navigation from "components/Navigation/Index"
import RaceParticipationEdit from "components/RaceParticipationEdit"
import React from "react"
import { set } from "core/utils/setValue"
import { useParams } from "react-router-dom"
import useRace from "core/hooks/useRace"
import useSeasonTeams from "core/hooks/useSeasonTeams"

export default function RaceEditPage() {
  const params = useParams()
  const gpId = params.gpId
  const raceId = params.raceId

  const [successDisplay, setSuccessDisplay] = React.useState(false)
  const [errorDisplay, setErrorDisplay] = React.useState(false)
  const [participations, setParticipations] = React.useState([])

  const { race, refetch, isLoading, updateRaceParticipations } = useRace(
    gpId,
    raceId
  )

  const { teams, isLoading: isLoadingTeams } = useSeasonTeams(race?.seasonId)

  React.useEffect(() => {
    if (race != null) {
      setParticipations(race.participations)
    }
  }, [race])

  const updateParticipation = (participationId, field, value) => {
    const participationsUpdated = participations.map((p) => {
      if (p.id === participationId) {
        set(p, field, value)
      }
      return p
    })

    setParticipations(participationsUpdated)
  }

  const updateTeam = (participationId, teamId) => {
    const team = teams.find((t) => t.teamId === teamId)
    const participationsUpdated = participations.map((p) => {
      if (p.id === participationId) {
        set(p, "team.id", team.teamId)
        set(p, "team.name", team.shortName)
        set(
          p,
          "team.constructorManufacturer.id",
          team.constructors[0].constructor.id
        )
        set(
          p,
          "team.constructorManufacturer.name",
          team.constructors[0].constructor.name
        )
        set(
          p,
          "team.engineManufacturer.id",
          team.constructors[0].engineManufacturer.id
        )
        set(
          p,
          "team.engineManufacturer.name",
          team.constructors[0].engineManufacturer.name
        )
      }
      return p
    })

    setParticipations(participationsUpdated)
  }

  const updateTeamConstructor = (participationId, teamId, value) => {
    const team = teams.find((t) => t.teamId === teamId)
    const construtorId = value.split("+")[0]
    const engineManufacturerId = value.split("+")[1]

    const constructor = team.constructors.find(
      (c) =>
        c.constructor.id === construtorId &&
        c.engineManufacturer.id === engineManufacturerId
    )
    const participationsUpdated = participations.map((p) => {
      if (p.id === participationId) {
        set(p, "team.constructorManufacturer.id", constructor.constructor.id)
        set(
          p,
          "team.constructorManufacturer.name",
          constructor.constructor.name
        )
        set(p, "team.engineManufacturer.id", constructor.engineManufacturer.id)
        set(
          p,
          "team.engineManufacturer.name",
          constructor.engineManufacturer.name
        )
      }
      return p
    })

    setParticipations(participationsUpdated)
  }

  const getTeamsByLegacyConstructor = (constructorLegacyId) => {
    return teams.filter((team) => {
      return team.constructors.some(
        (c) => c.constructorLegacy.id === constructorLegacyId
      )
    })
  }

  const handleSubmit = () => {
    updateRaceParticipations({
      participations,
    }).then((response) => {
      if (response.ok === true) {
        setSuccessDisplay(true)
        refetch()
      } else {
        setErrorDisplay(true)
      }
    })
  }

  if (isLoading || isLoadingTeams) {
    return <LoadingProgress />
  }

  return (
    <>
      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='h2'>Edit Race</Typography>
        </Grid>
        <Grid item xs={12}>
          <Navigation
            navs={[
              { link: `/grands-prix`, title: "Grands Prix" },
              { link: `/grands-prix/${gpId}/edit`, title: gpId },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          {successDisplay && (
            <Alert
              icon={<CheckCircleOutline fontSize='inherit' />}
              severity='success'
            >
              This is a success Alert.
            </Alert>
          )}
          {errorDisplay && (
            <Alert severity='error'>This is a error Alert.</Alert>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='gpId'
            label='gp id'
            defaultValue={race.grandPrixId}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={6}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='gpName'
            label='gp name'
            defaultValue={race.grandPrixName}
            fullWidth
            disabled
          />
        </Grid>

        <Grid
          item
          xs={6}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='gpRound'
            label='gp round'
            defaultValue={race.grandPrixRound}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='raceId'
            label='Id'
            defaultValue={race.id}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='raceType'
            label='Type'
            defaultValue={race.type}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='raceStartDate'
            label='Start Date'
            defaultValue={race.date}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='raceEndDate'
            label=' End Date'
            defaultValue={race.endDate}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
          alignSelf='stretch'
        >
          <Table stickyHeader aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell size='small'>Pos</TableCell>
                <TableCell size='small'>Fs Pos</TableCell>
                <TableCell>Grid / Laps / Points</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>DriverId</TableCell>
                <TableCell>ConstructorId</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Constructor Man.</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {race.participations.map((participation) => (
                <RaceParticipationEdit
                  key={participation.id}
                  participation={participation}
                  updateParticipation={updateParticipation}
                  updateTeam={updateTeam}
                  updateTeamConstructor={updateTeamConstructor}
                  setErrorDisplay={setErrorDisplay}
                  setSuccessDisplay={setSuccessDisplay}
                  teams={getTeamsByLegacyConstructor(
                    participation.constructor.id
                  )}
                />
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
          alignSelf='stretch'
        >
          <Button size='medium' variant='contained' onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
