import apiService from "core/services/network/api"
import responseToJson from "core/utils/reponseToJson"

export const seasons = {
  getSeason: (seasonId, token) => {
    return apiService
      .getData(`v1/seasons/${seasonId}`, token)
      .then(responseToJson)
  },
  getSeasons: (token) => {
    return apiService.getData(`v1/seasons`, token).then(responseToJson)
  },
  getSeasonTeams: (seasonId, token) => {
    return apiService
      .getData(`v1/seasons/${seasonId}/teams`, token)
      .then(responseToJson)
  },
}
